import { Container, Row, Col } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import { useState, useEffect } from 'react'
// import { text } from '@fortawesome/fontawesome-svg-core'
import HeaderImg2 from '../assets/img/HeaderImg2.png'
import 'animate.css'
import TrackVisibility from 'react-on-screen';

const Banner = () => {
    const [loopNum, setLoopNum] = useState(0)
    const [isDeleting, setIsDelecting] = useState(false)
    const toRotate = ["web developer", "problem solver", "person"]
    const [text, setText] = useState('')
    const [delta, setDelta] = useState(300 - Math.random() * 100)
    const period = 2000

    useEffect(() => {
        let ticker = setInterval(() => {
            tick()
        }, delta)

        return () => { clearInterval(ticker)}
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length
        let fullText = toRotate[i]
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1)

        setText(updatedText)

        if (isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDelecting(true)
            setDelta(period)
        }

        else if (isDeleting && updatedText === '') {
            setIsDelecting(false)
            setLoopNum(loopNum + 1)
            setDelta(500)
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row  className='align-items-center'>
                    <Col xs={12} md={6} xl={7}> 
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <span className='tagline'>Welcome to my Portfolio</span>
                                    <h1>{`Hi I'm Wan, a `}<span className='wrap'>{text}</span></h1>
                                    <p>A well adverse web developer who understands languages.</p>
                                    <a
                                    role='button'
                                    className='btn btn-link'
                                    href='mailto:notreal@notreal.co'
                                    >
                                            <button className='butt-connect' onClick={() => console.log('connected')} >
                                                <span>
                                                    Connect <ArrowRightCircle size={25} />
                                                </span>
                                            </button>
                                    </a>
                                </div>
                            }
                        </TrackVisibility>
                    </Col>
                
                    <Col xs={12} md={6} xl={5}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                                    <img src={HeaderImg2} alt="Header Img"/>
                                </div>
                            }
                        </TrackVisibility>
                    </Col>   
                </Row>
            </Container>
        </section>
    )
}

export default Banner