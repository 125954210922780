import { Label, Col, FormGroup } from "reactstrap";
import { Formik, Field, Form } from 'formik'
// import validateContactForm from "../utils/validateContactForm";
import { ErrorMessage } from "formik";
import { ArrowRightCircle } from 'react-bootstrap-icons'

const ContactForm = () => {
    const handleSubmit = (values,{ resetForm}) => {
        console.log('form values: ', values)

        resetForm()
    }
    const validateContactForm = (values) => {
        const errors = {}
        
        if (!values.firstName) {
            errors.firstName ='required'
    
        }
        else if(values.firstName.length < 2) {
            errors.firstName = 'Must be at least 2 characters.'
        }
        else if(values.firstName.length > 15) {
            errors.firstName = 'Must be 15 characters or less'
        }
    
        if (!values.lastName) {
            errors.lastName ='Required'
        }
        else if(values.lastName.length < 2) {
            errors.lastName = 'Must be at least 2 characters.'
        }
        else if(values.lastName.length > 15) {
            errors.lastName = 'Must be 15 characters or less'
        }
    
        const reg = /^\d+$/
        if (!reg.test(values.phoneNum)) {
            errors.phoneNum = 'The phone number should contain only numbers.'
        }
    
        if (!values.email.includes('@')) {
            errors.email = 'Email should contain a @'
        }
    
        return errors
        
    
    }
    
    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                phoneNum: '',
                email: '',
                agree: false,
                contactType: 'By Phone',
                feedback: ''
            }}

            onSubmit={handleSubmit}
            validate={validateContactForm}

        >

            <Form>
                <FormGroup row>
                    <Label htmlFor='firstName' md='2'>First Name</Label>
                    <Col md='10'>
                        <Field className='form-control' name='firstName'/>
                        <ErrorMessage name='firstName'>
                            {(msg) => <p className='text-danger'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='lastName' md='2'>Last Name</Label>
                    <Col md='10'>
                        <Field className='form-control' name='lastName'/>
                        <ErrorMessage name='lastName'>
                            {(msg) => <p className='text-danger'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='phoneNum' md='2'>Phone</Label>
                    <Col md='10'>
                        <Field className='form-control' name='phoneNum' />
                        <ErrorMessage name='phoneNum'>
                            {(msg) => <p className='text-danger'>{msg}</p>}
                        </ErrorMessage> 
                    </Col> 
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='email' md='2'>Email</Label>
                    <Col md='10'>
                        <Field className='form-control' name='email' />
                        <ErrorMessage name='email'>
                            {(msg) => <p className='text-danger'>{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                        <Label check md={{ size: 4, offset: 2 }}>
                            <Field
                                name='agree'
                                type='checkbox'
                                className='form-check-input'
                            />{' '}
                            May we contact you?
                        </Label>
                    <Col md='4'>
                        <Field className='form-control' name='contactType' as='select'> 
                            <option>By Phone</option>
                            <option>By Email</option>
                        </Field> 
                    </Col>    
                </FormGroup>
                <FormGroup row>
                    <Label htmlFor='feedback' md='2'>Your Feedback</Label>
                    <Col md='10'>
                        <Field className='form-control' name='feedback' as='textarea' rows='12'/>    
                    </Col> 
                </FormGroup>
                <FormGroup row>
                    <Col md={{size: 10, offset: 2}}>
                        <a
                                role='button'
                                className='btn btn-link'
                                href='mailto:notreal@notreal.co'
                            >
                                <button className='butt-connect' onClick={() => console.log('connected')} >
                                    <span>
                                        Connect <ArrowRightCircle size={25} />
                                    </span>
                                </button>
                        </a>
                    
                    </Col>
                </FormGroup>
            </Form>
        </Formik>
    )
}

export default ContactForm