
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import projImg1 from '../assets/img/projImg1.jpeg'
import ecommerce from '../assets/img/ecommerce.png'
import mobile1 from '../assets/img/mobile1.PNG'
import mobile2 from '../assets/img/mobile2.png'
import TrackVisibility from "react-on-screen";
import 'animate.css'

const Projects = () => {
    const projects = [
        {
          title: "Rideshare clone",
          description: "First half",
          imgUrl: mobile1,
        },
        {
          title: "Rideshare clone 2",
          description: "Second half",
          imgUrl: mobile2,
        },
        {
          title: "Ecommerce",
          description: "React ecommerce website with animation and search function",
          imgUrl: ecommerce,
        },
    ]

    return (
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col>
                    <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                    <h2>Projects</h2>
                        <p> In the realm of upcoming projects, Wan is brimming with innovative ideas poised to transform concepts into reality. With a strong analytical foundation, he possess the unique ability to dissect market trends, consumer behaviors, and emerging technologies. Armed with persuasive communication skills, he can convincingly convey these ideas to stakeholders, fostering enthusiasm and support. These forthcoming projects are not just innovative concepts; they represent a visionary approach, merging analytical insights with persuasive narratives. Wan is driven by a passion for excellence, ensuring that each idea is meticulously crafted into a viable project. Through dedication and expertise, he is on the verge of ushering in a new wave of transformative initiatives, promising to redefine industry standards and inspire others in the process.
                        </p>
                                </div>
                    }
                    </TrackVisibility>
                        
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Row>
                                <Col sm={12}>
                                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id='pills-tab'>
                                    <Nav.Item>
                                    <Nav.Link eventKey="first">Tab One</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="second">Tab Two</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="third">Tab Three</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            {
                                                projects.map((project, index) => {
                                                    return (
                                                        <ProjectCard
                                                            key={index}
                                                            {...project}
                                                        />
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                                    <Tab.Pane eventKey="third">Third tab content</Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Projects 