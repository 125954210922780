import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import logo from '../assets/img/logo.svg'


const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home')
    const [ scrolled, setScrolled] = useState(false)

    useEffect (() => {
        const onScroll = () => {
            if ( window.scrollY > 50) {
                setScrolled(true)
            }
            else {
                setScrolled(false)
            }
        }

        window.addEventListener('scroll', onScroll)
        
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value)
    }

    return (
        <Navbar expand="lg" className={scrolled ? 'scrolled' : ''}>
        <Container>
            <Navbar.Brand href="#home">
                <img src={logo} alt='logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" >
                <span className='navbar-toggler-icon'></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link' } onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link' } onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link' } onClick={() => onUpdateActiveLink('project')}>Projects</Nav.Link>
                {/* May not need below for homepage */}
                {/* <Nav.Link href="#contact" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link' } onClick={() => onUpdateActiveLink('contact')}>Contact Me</Nav.Link> */}
            </Nav>
                <span className='navbar-text'>
                    <div className='social-icon'>
                        {/* awesome font icons twitter */ }
                        <a
                            className='btn btn-social-icon btn-twitter'
                            href='http://twitter.com/'
                        >
                            <FontAwesomeIcon icon={faTwitter} className='iconColor'/>
                        </a>
                        {/* awesome font icons instagram */ }
                        <a
                            className='btn btn-social-icon btn-instagram'
                            href='http://instagram.com/'
                        >
                            <FontAwesomeIcon icon={faInstagram} className='iconColor'/>
                        </a>
                        {/* awesome font icons github */ }
                        <a
                            className='btn btn-social-icon btn-github'
                            href='https://github.com/wcao5013'
                        >
                            <FontAwesomeIcon icon={faGithub} className='iconColor'/>
                        </a>
                       
                    </div>
                    
                    <a
                        role='button'
                        className='btn btn-link'
                        href='mailto:notreal@notreal.co'
                    >
                        <button className='butt-connect' onClick={() => console.log('connected')} >
                            <span>
                                Let's Connect
                            </span>
                        </button>
                    </a>
                </span>
            </Navbar.Collapse>
        </Container>
        </Navbar>
  );
}

export default NavBar;