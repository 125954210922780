import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HTML from '../assets/img/HTML.png'
import CSS from '../assets/img/CSS.png'
import JS from '../assets/img/JS.png'
import React from '../assets/img/React.svg'


const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <section className='skill' id='skills'>
            <Container>
                <Row>
                    <Col>
                        <div className='skill-box'>
                            <h2>Skills</h2>
                            <p>Wan is a highly analytical and perceptive professional, possessing a keen ability to dissect complex issues and develop strategic solutions. With a sharp analytical mind, he excel in identifying patterns, trends, and insights that drive informed decision-making. Additionally, he exhibit exceptional persuasive skills, articulating ideas and proposals with clarity and conviction. This ability not only enables him to influence others effectively but also to negotiate and navigate challenging situations with finesse. Wan consistently demonstrates a relentless commitment to excellence in his role, setting high standards for himself and continuously striving to exceed them. His combination of analytical prowess, persuasive communication, and dedication to excellence make him an invaluable asset in any professional setting.
                            </p>
                            <Carousel responsive={responsive} infinite={true} className='skill-slider'>
                                <div className='item'>
                                    <img src={HTML} alt='' width={1000} height={200}/>
                                    <h5>HTML</h5>
                                </div>

                                <div className='item'>
                                    <img src={CSS} alt='' width={1000} height={200}/>
                                    <h5>CSS</h5>
                                </div>

                                <div className='item'>
                                    <img src={JS} alt='' width={1000} height={200}/>
                                    <h5>Javascript</h5>
                                </div>

                                <div className='item'>
                                    <img src={React} alt='' width={1000} height={200}/>
                                    <h5>React</h5>
                                </div>

                                <div className='item'>
                                    <img src={React} alt='' width={1000} height={200}/>
                                    <h5>React Native</h5>
                                </div>

                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Skills