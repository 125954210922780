import { Container, Row, Col } from "react-bootstrap"
import ContactForm from "./ContactForm"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className='row-content align-items-center'>
                    <Col sm='4'>
                        <h5>Our Address</h5>
                        <address>
                            123 abc dr
                            <br />
                            New York, New York 11002
                            <br />
                            U.S.A.
                        </address>
                    </Col>
                    <Col>
                            <a
                            role='button'
                            className='btn btn-link'
                            href='tel:+12065551234'
                            >
                                <FontAwesomeIcon icon={faPhone} className='iconColor'/>
                            </a>
                            <a
                                role='button'
                                className='btn btn-link'
                                href='mailto:notreal@notreal.co'
                            >
                                <FontAwesomeIcon icon={faEnvelope} className='iconColor'/>
                            </a>
                    </Col>
                </Row>

                <Row className='row-content'>
                    <Col xs='12'>
                        <h2>Contact me</h2>
                        <hr />
                    </Col>
                    <Col md='10'>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Contact