
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className='align-item-center'>
                    
                    <Col sm='6' className='text-center text-sm-end'>
                        <div className="social-icon">
                            <h5>Social</h5>
                            <a
                            className='btn btn-social-icon btn-twitter'
                            href='http://twitter.com/'
                            >
                                <FontAwesomeIcon icon={faTwitter} className='iconColor'/>
                            </a>
                            {/* awesome font icons instagram */ }
                            <a
                                className='btn btn-social-icon btn-instagram'
                                href='http://instagram.com/'
                            >
                                <FontAwesomeIcon icon={faInstagram} className='iconColor'/>
                            </a>
                            {/* awesome font icons github */ }
                            <a
                                className='btn btn-social-icon btn-github'
                                href='http://github.com/'
                            >
                                <FontAwesomeIcon icon={faGithub} className='iconColor'/>
                            </a>
                            <a
                            role='button'
                            className='btn btn-link'
                            href='tel:+12065551234'
                            >
                                <FontAwesomeIcon icon={faPhone} className='iconColor'/>
                            </a>
                            <a
                                role='button'
                                className='btn btn-link'
                                href='mailto:notreal@notreal.co'
                            >
                                <FontAwesomeIcon icon={faEnvelope} className='iconColor'/>
                            </a>
                                
                        </div>
                    </Col>
                    <Col sm='6' className='text-center '>
                        <p>Copyright 2023 reserve</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer